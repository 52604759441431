// Function component (container, using React, i18next & React Responsive hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { scansIndex } from '../../ducks/scans'
import { toggleNewEvent, setMeal } from '../../ducks/event'

import L10nLink from '../shared/navigation/L10nLink'

import { BarcodeOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Image, Button } from 'antd'

import renderCardList from '../library/renderCardList'

const ScanHistoryContainer = () => {
	const dispatch = useDispatch()

	const { isAuthenticated } = useAuth0()

	const {
		i18n: { language },
	} = useTranslation(['scanner'])

	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id

	useEffect(() => {
		const listScans = async () => {
			dispatch(scansIndex(language, { of: personId }, { size: 12 }))
		}

		listScans()
	}, [language, personId, dispatch])

	const handleFoodClick = (foodId) => {
		dispatch(setMeal({ foodId }))
		dispatch(toggleNewEvent())
	}

	const foodItems = build(apiData, 'scan')
		?.filter((fi) => fi.person?.id === personId)
		?.map((fi) => fi.foodItem)
		?.filter((v, i, a) => a.indexOf(v) === i)
	const dataSource = foodItems?.map(
		({ id, food: { image, name: title, id: foodId, description } }) => ({
			type: 'foodItem',
			id,
			avatar: (
				<Avatar
					icon={<BarcodeOutlined />}
					src={image?.url && <Image src={image?.thumbUrl} preview={{ src: image.url }} />}
					size={44}
					shape="square"
				/>
			),
			title: <L10nLink to={`/library/food-item/${id}`}>{title}</L10nLink>,
			extra: isAuthenticated && (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
			),
			description:
				title !== description ? (
					<L10nLink to={`/library/food-item/${id}`}>{description}</L10nLink>
				) : null,
		}),
	)

	return renderCardList(dataSource)
}

export default ScanHistoryContainer
