import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/foods'

export const foodsAnalyze = (locale, accessToken, id) => (dispatch) => {
	const endpoint = localizePath(locale, `${path}/${id}/analyze`)
	const method = 'PATCH'
	const options = initRequestOptions({ accessToken, method })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
