// Function component (presentational):

import React from 'react'

import { colors } from '../../styles/colors'

import NavButton from '../shared/styledComponents/button/NavButton'

import { Badge } from 'antd'

import StyledLibraMenu from './LibraMenuStyles'

const LibraMenu = ({ inAssistance, isConnected, renderAssistantAvatar, showActions, t }) => {
	const renderAssistant = () => (
		<Badge
			dot={inAssistance}
			status={isConnected ? 'processing' : 'default'}
			color={isConnected && colors.libraStar}
		>
			<NavButton
				// key="libra-menu-assistant"
				icon={renderAssistantAvatar()}
				onClick={showActions}
				$hasAvatarIcon
			>
				{t('nav:libraMenu.actions.shortTitle')}
			</NavButton>
		</Badge>
	)

	return (
		<StyledLibraMenu
		// id="libra-menu"
		>
			{renderAssistant()}
		</StyledLibraMenu>
	)
}

export default LibraMenu
