// Function component (presentational):

import React from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import NavButton from '../shared/styledComponents/button/NavButton'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import FieldContainer from '../field/FieldContainer'

import renderCardList from './renderCardList'
import SearchForm from './SearchForm'

import pattern from '../../assets/patterns/abstract-1.jpg'

import { AppstoreOutlined, ApiOutlined, SyncOutlined } from '@ant-design/icons'
import {
	Empty,
	// Tag,
	Skeleton,
} from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const Library = ({
	setSearchTerm,
	pageNumber,
	pageCount,
	initialValues,
	isNutrition,
	isMind,
	isFitness,
	scope,
	handleSectionChange,
	implicitField,
	options,
	loading,
	searchData,
	searchMoreData,
	dataSource,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const children = []

	const renderChildren = () =>
		dataSource?.length && (
			<TabPaneWrapper key="search-results">
				{renderCardList(dataSource, ['standardFood', 'foodItem', 'recipe'].includes(scope))}

				{loading && (
					<Card>
						<Skeleton loading avatar paragraph={{ rows: 1 }} active></Skeleton>
					</Card>
				)}
			</TabPaneWrapper>
		)

	const hasSearchResults = pageNumber >= 1
	hasSearchResults &&
		children.push(dataSource?.length ? renderChildren() : <Empty key="no-results" />)

	const endedSearch = pageNumber === pageCount
	const showMore = hasSearchResults && !endedSearch && !loading
	showMore &&
		children.push(
			<Card key="search-more-data" style={{ margin: '8px auto', width: '100%' }}>
				<BlockRoundButton type="default" onClick={searchMoreData}>
					{renderLabel({
						icon: <ApiOutlined />,
						label: t('library:action.more.title'),
						extra: <SyncOutlined />,
					})}
				</BlockRoundButton>
			</Card>,
		)

	const standardFoodItem = {
		key: 'standardFood',
		label: t('library:scopes.standardFood.title'),
		children,
	}
	const foodItemItem = {
		key: 'foodItem',
		label: t('library:scopes.foodItem.title'),
		children,
	}
	const recipeItem = {
		key: 'recipe',
		label: t('library:scopes.recipe.title'),
		children,
	}
	const mealItem = { key: 'meal', label: t('library:scopes.meal.title'), disabled: true }
	const mealProgramItem = {
		key: 'mealProgram',
		label: t('library:scopes.mealProgram.title'),
		disabled: true,
	}
	isNutrition && items.push(standardFoodItem, foodItemItem, recipeItem, mealItem, mealProgramItem)

	const introspectionItem = {
		key: 'introspection',
		label: t('library:scopes.introspection.title'),
		children,
	}
	const introspectionProgramItem = {
		key: 'introspectionProgram',
		label: t('library:scopes.introspectionProgram.title'),
		disabled: true,
	}
	const meditationItem = {
		key: 'meditation',
		label: t('library:scopes.meditation.title'),
		disabled: true,
	}
	isMind && items.push(introspectionItem, introspectionProgramItem, meditationItem)

	const activityKindItem = {
		key: 'activityKind',
		label: t('library:scopes.activityKind.title'),
		children,
	}
	const workoutItem = { key: 'workout', label: t('library:scopes.workout.title'), disabled: true }
	const fitnessProgramItem = {
		key: 'fitnessProgram',
		label: t('library:scopes.fitnessProgram.title'),
		disabled: true,
	}
	const fitnessTestItem = {
		key: 'fitnessTest',
		label: t('library:scopes.fitnessTest.title'),
		disabled: true,
	}
	isFitness && items.push(activityKindItem, workoutItem, fitnessProgramItem, fitnessTestItem)

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction>
				<Card $size={'wide'} $isAction>
					<SearchForm
						initialValues={initialValues}
						setSearchTerm={setSearchTerm}
						scope={scope}
						options={options}
						loading={loading}
						searchData={searchData}
						t={t}
					/>
					{/* <div style={{ margin: '8px 8px 0 8px', display: 'flex', justifyContent: 'center' }}>
						<Tag bordered={false}>Tag 1</Tag>
						<Tag bordered={false}>Tag 2</Tag>
						<Tag bordered={false}>Tag 3</Tag>
					</div> */}
				</Card>

				<FieldContainer field={implicitField} />
			</PageAction>

			<Tabs
				activeKey={scope}
				tabBarExtraContent={{
					left: (
						<NavButton
							icon={<AppstoreOutlined />}
							// onClick={toggleDisplay}
							disabled
						/>
					),
				}}
				items={items}
				onChange={handleSectionChange}
				$withLeftExtra
			></Tabs>

			{/* {searchTerm && (
				<SectionWrapper
				// id="library-results-wrapper"
				>
					<SectionTitle>
						<Title level={3}>
							{loading
								? t('library:results.searching')
								: dataSource?.length
								? t('library:results.found')
								: t('library:results.notFound')}
						</Title>
					</SectionTitle>

					{dataSource?.length !== 0 && (
						<ListWrapper>
							<List
								dataSource={dataSource}
								renderItem={(item) => (
									<ListItem>
										<Card title={renderTitle(item)} extra={renderExtra(item)}>
											<L10nLink to={`/library/${_.kebabCase(item.type)}/${item.id}`}>
												<Paragraph ellipsis={{ rows: 3 }}>{item.description}</Paragraph>
											</L10nLink>
										</Card>
									</ListItem>
								)}
							/>
						</ListWrapper>
					)}
				</SectionWrapper>
			)} */}

			{/* {!searchTerm && (
				<SectionWrapper
				// id="library-suggestions-wrapper"
				>
					<SectionTitle>
						<Title level={3}>{t('library:suggestions.title')}</Title>
					</SectionTitle>
				</SectionWrapper>
			)} */}
		</>
	)
}

export default Library
