// Function component (container, using React, i18next & React Responsive hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'

import { foodItemsIndex } from '../../ducks/foodItems'
import { toggleNewEvent, setMeal } from '../../ducks/event'

import L10nLink from '../shared/navigation/L10nLink'

import { BarcodeOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Image, Button } from 'antd'

import renderCardList from '../library/renderCardList'

const ScanPopularityContainer = () => {
	const dispatch = useDispatch()

	const { isAuthenticated } = useAuth0()

	const {
		i18n: { language },
	} = useTranslation(['scanner'])

	useEffect(() => {
		const listFoodItems = async () => {
			const accessToken = ''

			dispatch(foodItemsIndex(language, accessToken, { isOrderedByScans: true }, { size: 12 }))
		}

		listFoodItems()
	}, [language, dispatch])

	const handleFoodClick = (foodId) => {
		dispatch(setMeal({ foodId }))
		dispatch(toggleNewEvent())
	}

	const apiData = useSelector((state) => state.apiData)

	const foodItems = build(apiData, 'foodItem')
		?.filter((fi) => fi.scansCount > 0)
		?.sort((a, b) => b.scansCount - a.scansCount)
	const dataSource = foodItems?.map(
		({ id, food: { image, name: title, id: foodId, description } }) => ({
			type: 'foodItem',
			id,
			avatar: (
				<Avatar
					icon={<BarcodeOutlined />}
					src={image?.url && <Image src={image?.thumbUrl} preview={{ src: image.url }} />}
					size={44}
					shape="square"
				/>
			),
			title: <L10nLink to={`/library/food-item/${id}`}>{title}</L10nLink>,
			extra: isAuthenticated && (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
			),
			description:
				title !== description ? (
					<L10nLink to={`/library/food-item/${id}`}>{description}</L10nLink>
				) : null,
		}),
	)

	return renderCardList(dataSource)
}

export default ScanPopularityContainer
