import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

import { redirectUri } from '../contexts/auth/authConfig'

const isNative = Capacitor.isNativePlatform()

const signInCap = async (loginWithRedirect, opts) => {
	await loginWithRedirect({
		async openUrl(url) {
			await Browser.open({ url, windowName: '_self' })
		},
		...opts,
	})
}

const signInWeb = async (loginWithRedirect, opts) =>
	await loginWithRedirect({
		appState: { returnTo: window.location.pathname },
		...opts,
	})

export const signIn = (loginWithRedirect, opts = {}) =>
	isNative ? signInCap(loginWithRedirect, opts) : signInWeb(loginWithRedirect, opts)

export const signUp = (loginWithRedirect) =>
	signIn(loginWithRedirect, { authorizationParams: { screen_hint: 'signup' } })

const signOutCap = async (logout) => {
	await logout({
		logoutParams: { returnTo: redirectUri },
		async openUrl(url) {
			await Browser.open({ url, windowName: '_self' })
		},
	})
}

const signOutWeb = (logout) =>
	logout({
		logoutParams: { returnTo: window.location.origin },
	})

export const signOut = (logout) => (isNative ? signOutCap(logout) : signOutWeb(logout))
