// Function component (presentational):

import React, { Suspense } from 'react'

import _ from 'lodash'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import List from '../shared/styledComponents/list/List'

import L10nLink from '../shared/navigation/L10nLink'

import NutritionFactsContainer from '../nutrition/NutritionFactsStatefulContainer'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon, {
	LoadingOutlined,
	PlusOutlined,
	ThunderboltOutlined,
	HeartOutlined,
	ProfileOutlined,
	NodeCollapseOutlined,
	EyeOutlined,
	// ControlOutlined,
	// CalculatorOutlined,
	UnorderedListOutlined,
	OrderedListOutlined,
	BulbOutlined,
} from '@ant-design/icons'
import {
	Avatar,
	Image,
	Button,
	Descriptions,
	Rate,
	Statistic,
	Switch,
	Divider,
	Steps,
	Typography,
	Tooltip,
} from 'antd'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as RecipeIcon } from '../../assets/icons/saucepan.svg'
import { ReactComponent as Liquid } from '../../assets/icons/water-glass.svg'
import { ReactComponent as Solid } from '../../assets/icons/fork-and-knife.svg'
import { ReactComponent as LibraAssistant } from '../../assets/icons/libra.assistant.svg'

const { Text } = Typography

const Recipe = ({
	section,
	handleSectionChange,
	isAuthenticated,
	recipe,
	instructions,
	step,
	handleStepChange,
	withDictation,
	toggleDictation,
	handleAdd,
	handleAnalyze,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const overviewChildren = []

	const food = recipe?.food
	const difficulty = recipe?.difficultyValue

	const infoItems = [
		{
			key: 'difficulty',
			label: t('recipe:sections.overview.info.difficulty'),
			children: <Rate disabled value={difficulty} />,
			span: 2,
		},
		{
			key: 'physicalState',
			label: t('recipe:sections.overview.info.physicalState'),
			children: food?.physicalStateLabel,
			span: 2,
		},
	]

	const categoryName = food?.categoryName
	const categoryItem = {
		key: 'category',
		label: t('recipe:sections.overview.info.category'),
		children: categoryName,
		span: 2,
	}
	categoryName && infoItems.push(categoryItem)

	const renderInfo = () => (
		<Card
			title={renderLabel({
				icon: <ProfileOutlined />,
				label: t('recipe:sections.overview.info.title'),
			})}
			style={{ height: '100%' }}
		>
			<Descriptions column={2} items={infoItems} style={{ padding: '0 6px' }} />
		</Card>
	)

	const renderFoodLabel = ({
		name,
		isLiquid,
		url,
		sqUrl,
		type = 'standardFood',
		id,
		serving,
		volume,
		mass,
	}) =>
		renderLabel({
			icon: (
				<Avatar
					icon={
						<Icon
							component={
								!name ? Nutrition : type === 'recipe' ? RecipeIcon : isLiquid ? Liquid : Solid
							}
						/>
					}
					src={url && <Image src={sqUrl} preview={{ src: url }} />}
					shape="square"
				/>
			),
			label: (
				<L10nLink to={`/library/${_.kebabCase(type)}/${id}`}>
					{name ?? t('recipe:sections.ingredients.ingredientsList.single')}
				</L10nLink>
			),
			extra: !name ? (
				<LoadingOutlined />
			) : serving ? (
				serving
			) : volume ? (
				volume + ' ml'
			) : mass ? (
				mass + ' g'
			) : null,
		})

	const standardFood = recipe?.standardFood
	const stdFood = standardFood?.food
	const renderStandardFood = () => (
		<Card
			title={renderLabel({
				icon: <NodeCollapseOutlined />,
				label: t('recipe:sections.overview.standardFood.title'),
			})}
			style={{ height: '100%' }}
		>
			{renderFoodLabel({
				id: standardFood.id,
				name: stdFood.name,
				isLiquid: stdFood.physicalState === 'liquid',
				url: standardFood.cartoonImage?.url ?? stdFood.image?.url,
				sqUrl: standardFood.cartoonImage?.thumbUrl ?? stdFood.image?.thumbUrl,
			})}
		</Card>
	)

	const renderStats = ({ title, value, prefix }) => (
		<Card $size={'small'} style={{ height: '100%' }}>
			<Statistic
				title={title}
				value={value}
				prefix={prefix}
				style={{ padding: '1px 6px 6px 2px' }}
			/>
		</Card>
	)

	const viewsCount = food?.viewsCount
	const renderViews = () =>
		renderStats({
			title: t('recipe:sections.overview.stats.views'),
			value: viewsCount,
			prefix: <EyeOutlined />,
		})

	const enoughViewsCount = viewsCount >= 12
	const renderOverviewChildren = () => (
		<TabPaneWrapper key="overview-pane">
			{renderInfo()}
			{standardFood && renderStandardFood()}
			{enoughViewsCount && renderViews()}
		</TabPaneWrapper>
	)
	recipe && overviewChildren.push(renderOverviewChildren())

	const overviewItem = {
		key: 'overview',
		label: t('recipe:sections.overview.title'),
		children: overviewChildren,
	}

	const ingredients = recipe?.recipeIngredients
	const ingredientsData =
		ingredients
			?.map((ri) => ({
				type: ri.ingredient?.foodable?.type,
				id: ri.ingredient?.foodable?.id,
				name: ri.ingredient?.name,
				isLiquid: ri?.ingredient?.physicalState === 'liquid',
				url: ri.ingredient?.image?.url,
				sqUrl: ri.ingredient?.image?.thumbUrl,
				mass: Math.round(+ri.mass),
				volume: ri.volume && Math.round(+ri.volume),
				serving: ri.serving && Math.round(+ri.serving),
				servingSizeName: ri.servingSize?.foodPartMeasure?.foodPart?.name,
			}))
			?.sort((a, b) => b.mass - a.mass) ?? []

	const renderIngredientsList = () => (
		<Card
			title={renderLabel({
				icon: <UnorderedListOutlined />,
				label: t('recipe:sections.ingredients.ingredientsList.title'),
			})}
			style={{ height: '100%' }}
		>
			<List dataSource={ingredientsData} renderItem={renderFoodLabel} />
		</Card>
	)

	const renderIngredientsChildren = () => (
		<TabPaneWrapper key="ingredients-pane">{renderIngredientsList()}</TabPaneWrapper>
	)

	const ingredientsItem = {
		key: 'ingredients',
		label: t('recipe:sections.ingredients.title'),
		children: renderIngredientsChildren(),
		disabled: !ingredients,
	}

	const renderNotes = (notes) =>
		notes?.map((n, index) => (
			<Text key={index}>
				<blockquote style={{ margin: '8px 0' }}>{n}</blockquote>
			</Text>
		))

	const instructionsItems = instructions?.map((i) => ({
		title: (
			<Avatar.Group maxCount={11}>
				{i.previousInstructions?.map((sequenceNumber) => (
					<Tooltip
						key={sequenceNumber}
						title={t('recipe:sections.instructions.instructionsList.step', { sequenceNumber })}
					>
						<Avatar size="small">{sequenceNumber}</Avatar>
					</Tooltip>
				))}
				{i.ingredients?.map(({ title, url, foodableType, isLiquid }, index) => (
					<Tooltip key={index} title={title}>
						<Avatar
							icon={
								<Icon
									component={foodableType === 'recipe' ? RecipeIcon : isLiquid ? Liquid : Solid}
								/>
							}
							src={url}
							shape="square"
							size="small"
						/>
					</Tooltip>
				))}
			</Avatar.Group>
		),
		// description: i.description,
		description: (
			<>
				<Text type={step === i.sequenceNumber - 1 ? 'default' : 'secondary'}>{i.description}</Text>
				{i.notes?.length !== 0 && step === i.sequenceNumber - 1 && renderNotes(i.notes)}
			</>
		),
	}))

	const anyInstructionsItems = instructionsItems.length !== 0
	const renderInstructionsList = () => (
		<Card
			title={renderLabel({
				icon: <OrderedListOutlined />,
				label: t('recipe:sections.instructions.instructionsList.title'),
			})}
			style={{ height: '100%' }}
		>
			{renderLabel({
				icon: <Icon component={LibraAssistant} />,
				label: t('recipe:sections.instructions.instructionsList.dictation.label'),
				extra: (
					<Switch
						checked={withDictation}
						onChange={toggleDictation}
						disabled={!anyInstructionsItems}
						style={{ marginRight: 5 }}
					/>
				),
			})}

			{anyInstructionsItems && <Divider style={{ margin: '8px 0' }} />}

			<Steps
				current={step}
				items={instructionsItems}
				direction="vertical"
				onChange={handleStepChange}
			/>
		</Card>
	)

	const genericNotes = recipe?.genericNotes?.map((n) => n.description)
	const renderGenericNotes = () => (
		<Card
			title={renderLabel({
				icon: <BulbOutlined />,
				label: t('recipe:sections.instructions.genericNotes.title'),
			})}
			style={{ height: '100%' }}
		>
			<div style={{ padding: '5px 8px' }}>{renderNotes(genericNotes)}</div>
		</Card>
	)

	const anyGenericNotes = genericNotes && genericNotes.length !== 0
	const renderInstructionsChildren = () => (
		<TabPaneWrapper key="instructions-pane">
			{renderInstructionsList()}
			{anyGenericNotes && renderGenericNotes()}
		</TabPaneWrapper>
	)

	const instructionsItem = {
		key: 'instructions',
		label: t('recipe:sections.instructions.title'),
		children: renderInstructionsChildren(),
		disabled: !instructions,
	}

	const renderNutritionFactsChildren = () => (
		<TabPaneWrapper key="nutrition-facts-pane">
			<Suspense fallback={renderSkeletonCard()}>
				<NutritionFactsContainer food={food} />
			</Suspense>
		</TabPaneWrapper>
	)

	const nutritionFactsItem = {
		key: 'nutritionFacts',
		label: t('recipe:sections.nutritionFacts.title'),
		children: renderNutritionFactsChildren(),
		disabled: !food?.nutritionalComposition,
	}

	const image = food?.image
	const photoUrl = image?.url
	const photoMedUrl = image?.mediumUrl

	const renderPhotoGallery = () => (
		<Card $withSingleImage>
			<Image
				src={photoUrl}
				placeholder={<Image preview={false} src={photoMedUrl} />}
				style={{ borderRadius: 16 }}
			/>
		</Card>
	)

	const renderGalleryChildren = () => (
		<TabPaneWrapper key="gallery-pane">{photoUrl && renderPhotoGallery()}</TabPaneWrapper>
	)

	const anyImage = photoUrl

	const galleryItem = {
		key: 'gallery',
		label: t('recipe:sections.gallery.title'),
		children: renderGalleryChildren(),
		disabled: !anyImage,
	}

	items.push(overviewItem, ingredientsItem, instructionsItem, nutritionFactsItem, galleryItem)

	// const loadingButton = <Button type="text" shape="circle" icon={<ApiOutlined />} loading />

	const smallUrl = image?.smallUrl
	const src = photoUrl && (
		<Image src={photoUrl} placeholder={smallUrl && <Image preview={false} src={smallUrl} />} />
	)
	const avatar = (
		<Avatar icon={<Icon component={RecipeIcon} />} src={src} size={60} shape="square" />
	)

	const title = food?.name ?? t('recipe:page.title')
	const desc = food?.description
	const description = desc !== title ? desc : null
	const extra = !recipe ? (
		<LoadingOutlined />
	) : isAuthenticated ? (
		<Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} />
	) : null

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card
					$size={'wide'}
					$isAction
					$withBigAvatar
					$withSquareAvatar
					$withDescription={description}
				>
					<Card.Meta
						avatar={avatar}
						title={renderLabel({ label: title, extra })}
						description={description}
					/>
				</Card>

				<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<ThunderboltOutlined />}
						disabled={!food}
						loading={food && !food.analysis}
						onClick={handleAnalyze}
					>
						{t('recipe:actions.analyze')}
					</NavButton>
				</FloatMenu>

				<FloatMenu style={{ position: 'absolute', bottom: 0, right: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<HeartOutlined />}
						// onClick={handleFavorite}
						disabled
					>
						{t('recipe:actions.markFavorite')}
					</NavButton>
				</FloatMenu>
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange}></Tabs>
		</>
	)
}

export default Recipe
