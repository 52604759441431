// Function component (presentational):

import React from 'react'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

const renderCardList = (dataSource, hoverable = true) => {
	const withData = dataSource?.length > 0

	const renderCard = (item) => (
		<Card
			key={item.id}
			hoverable={hoverable}
			cover={item.url && <img alt="illustration" src={item.url} />}
			$withMeta
			$withBigAvatar
			$withSquareAvatar
			$withDescription={item.description}
		>
			<Card.Meta
				avatar={item.avatar}
				title={renderLabel({ label: item.title, extra: item.extra })}
				description={item.description}
			/>
		</Card>
	)

	const renderCards = () => withData && dataSource.map((item) => renderCard(item))

	return <>{renderCards()}</>
}

export default renderCardList
