import styled from 'styled-components'

const StyledScanner = styled.div`
	height: 240px;

	overflow: hidden;

	border-radius: 24px;

	> video {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
`

export default StyledScanner
