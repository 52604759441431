// Function component (presentational):

import React from 'react'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import {
	renderNavAppMenuHome,
	renderNavAppMenuCoaching,
	renderNavAppMenuLibrary,
	renderNavAppMenuShopping,
	renderNavAppMenuProfile,
	renderNavAppMenuJournal,
} from './renderNavAppMenuItems'

import { LeftOutlined } from '@ant-design/icons'

import StyledNavAppMenu from './NavAppMenuStyles'

const NavAppMenu = ({ isHub, sleekNav, navigate, navKey, withSideDash, t, isTabletL }) => {
	const historyLength = window.history.length
	const showBack = !isHub && historyLength > 3
	// const showNav = !sleekNav && isTabletL

	const renderNavAppMenuBack = () => (
		<NavButton icon={<LeftOutlined />} onClick={() => navigate(-1)}>
			{t('nav:appMenu.back')}
		</NavButton>
	)

	return (
		<>
			<StyledNavAppMenu
			// id="nav-app-menu"
			>
				{showBack ? renderNavAppMenuBack() : renderNavAppMenuHome(t, navKey)}
			</StyledNavAppMenu>

			{!sleekNav && !isTabletL && (
				<FloatMenu $bottom={0} $isCentered $withSideDash={withSideDash}>
					{renderNavAppMenuCoaching(t, navKey)}
					{renderNavAppMenuLibrary(t, navKey)}
					{renderNavAppMenuShopping(t, navKey)}
				</FloatMenu>
			)}
			{!sleekNav && isTabletL && (
				<FloatMenu $top={96} $left={0} $direction="column">
					{renderNavAppMenuShopping(t, navKey)}
					{renderNavAppMenuLibrary(t, navKey)}
					{renderNavAppMenuCoaching(t, navKey)}
				</FloatMenu>
			)}

			{!sleekNav && isTabletL && (
				<FloatMenu
					$top={96}
					$right={0}
					// $bottom={!isTabletL ? 0 : undefined}
					// $withSideDash={!isTabletL ? withSideDash : undefined}
				>
					{renderNavAppMenuProfile(t, navKey)}
				</FloatMenu>
			)}
			{!sleekNav && (
				<FloatMenu
					$top={isTabletL ? 144 : undefined}
					// $right={!isTabletL ? 0 : undefined}
					$right={0}
					$bottom={!isTabletL ? 0 : undefined}
					$withSideDash={!isTabletL ? withSideDash : undefined}
				>
					{renderNavAppMenuJournal(t, navKey)}
				</FloatMenu>
			)}
		</>
	)
}

export default NavAppMenu
