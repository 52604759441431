// Function component (container, using React, React Router, Auth0 & i18next hooks):

// React Effect hook.
import React, { useEffect } from 'react'
// React Router hook for routing.
import { useLocation } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import { Capacitor } from '@capacitor/core'
import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser'

import { Routes, Route, Navigate } from 'react-router-dom'

import ReactGA from 'react-ga4'

import { scrollToTop, scrollToId } from '../../utils/window'
import { gaEvents } from '../../utils/gaEvents'

import Redirect from '../shared/navigation/Redirect'
import AuthenticationGuard from '../shared/navigation/AuthenticationGuard'

import LoadingView from '../loading/LoadingView'
import LandingView from '../home/LandingView.jsx'
import DeckView from '../home/DeckView.jsx'
import SubscriptionView from '../subscription/SubscriptionView'
import SubscriptionConfirmationView from '../subscriptionConfirmation/SubscriptionConfirmationView.jsx'
import ReferralView from '../referral/ReferralView'
import OnboardingView from '../onboarding/OnboardingView'
import ProfileView from '../profile/ProfileView.jsx'
import JournalView from '../journal/JournalView.jsx'
import LibraryView from '../library/LibraryView'
import ScannerView from '../scanner/ScannerView'
import AssistanceView from '../assistance/AssistanceView'
import StandardFoodView from '../standardFood/StandardFoodView'
import FoodItemView from '../foodItem/FoodItemView'
import RecipeView from '../recipe/RecipeView'
import NotFoundView from '../notFound/NotFoundView'

import './App.css'

const TRACKING_ID = 'G-M39F55N85L'
ReactGA.initialize(TRACKING_ID)

const App = () => {
	let { pathname, hash } = useLocation()

	useEffect(() => {
		!hash ? scrollToTop() : scrollToId(hash)
	}, [pathname, hash])

	const { handleRedirectCallback, isLoading, isAuthenticated, user } = useAuth0()

	const isNative = Capacitor.isNativePlatform()

	const shouldHandleRedirectCallback = isNative
	useEffect(() => {
		if (shouldHandleRedirectCallback) return

		// Handle the `appUrlOpen` event and call `handleRedirectCallback`.
		CapApp.addListener('appUrlOpen', async ({ url }) => {
			if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
				await handleRedirectCallback(url)
			}
			// No-op on Android.
			await Browser.close()
		})
	}, [shouldHandleRedirectCallback, handleRedirectCallback])

	useEffect(() => {
		if (!user) return

		const {
			'https://libra.diet/logins_count': loginsCount,
			'https://libra.diet/connection': connection,
		} = user

		loginsCount === 1 ? gaEvents.signup(connection) : gaEvents.login(connection)
	}, [user])

	const {
		i18n: { language },
	} = useTranslation()

	if (isLoading) {
		// Loading auth...
		return <LoadingView />
	}

	return (
		<div
		// id="app"
		>
			<Routes>
				<Route path="/" element={<Navigate to={language} replace />} />

				<Route path="/:locale" element={isAuthenticated ? <DeckView /> : <LandingView />} />

				<Route
					path="/:locale/terms-and-conditions"
					element={
						<Redirect hostUrl="https://libra.co.com" path="/terms-and-conditions/" withLocale />
					}
				/>
				<Route
					path="/:locale/privacy-policy"
					element={<Redirect hostUrl="https://libra.co.com" path="/privacy-policy/" withLocale />}
				/>

				<Route path="/:locale/subscription" element={<SubscriptionView />} />
				<Route
					path="/:locale/subscription-confirmation/:key"
					element={<SubscriptionConfirmationView />}
				/>
				<Route path="/:locale/referral" element={<ReferralView />} />

				<Route
					path="/:locale/onboarding"
					element={<AuthenticationGuard component={OnboardingView} />}
				/>

				<Route
					path="/:locale/account?/:username?/profile"
					element={<AuthenticationGuard component={ProfileView} />}
				/>
				<Route path="/:locale/journal" element={<AuthenticationGuard component={JournalView} />} />

				<Route path="/:locale/library" element={<AuthenticationGuard component={LibraryView} />} />
				<Route path="/:locale/library/standard-food/:id" element={<StandardFoodView />} />
				<Route path="/:locale/library/food-item/:gtin" element={<FoodItemView />} />
				<Route
					path="/:locale/library/recipe/:id"
					element={<AuthenticationGuard component={RecipeView} />}
				/>
				<Route path="/:locale/scanner" element={<ScannerView />} />

				<Route
					path="/:locale/assistance"
					element={<AuthenticationGuard component={AssistanceView} />}
				/>

				<Route path="/callback" element={<LoadingView />} />

				<Route path="*" element={<NotFoundView />} />
			</Routes>
		</div>
	)
}

export default App
