// Function component (presentational):

import React, { Suspense } from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import ScanPopularityContainer from './ScanPopularityContainer'
import ScanHistoryContainer from './ScanHistoryContainer'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon, { LoginOutlined, AppstoreOutlined } from '@ant-design/icons'
import { ReactComponent as Torch } from '../../assets/icons/torch.svg'

import Reader from './ScannerStyles'

const Scanner = ({
	isAuthenticated,
	section,
	handleSectionChange,
	// toggleTorch,
	handleLogin,
	t,
	isDarkTheme,
}) => {
	const style = { maxHeight: 'calc(100vh - 304px)', overflow: 'auto' }
	const items = [
		{
			key: 'popularity',
			label: t('scanner:sections.popularity.title'),
			children: (
				<TabPaneWrapper key="popularity-pane" style={style}>
					<Suspense fallback={renderSkeletonCard()}>
						<ScanPopularityContainer />
					</Suspense>
				</TabPaneWrapper>
			),
		},
	]

	const historyItem = {
		key: 'history',
		label: t('scanner:sections.history.title'),
		children: (
			<TabPaneWrapper key="history-pane" style={style}>
				<Suspense fallback={renderSkeletonCard()}>
					<ScanHistoryContainer />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	isAuthenticated && items.unshift(historyItem)

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card $size={'wide'} style={{ height: 256, marginTop: -80 }}>
					<Reader id="reader"></Reader>
				</Card>

				<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<Icon component={Torch} />}
						// onClick={toggleTorch}
						disabled
					>
						{t('scanner:actions.turnOnTorch')}
					</NavButton>
				</FloatMenu>

				{!isAuthenticated && (
					<FloatMenu style={{ position: 'absolute', bottom: 0, right: 0, margin: '0 8px 8px 8px' }}>
						<NavButton icon={<LoginOutlined />} onClick={handleLogin}>
							{t('scanner:actions.login')}
						</NavButton>
					</FloatMenu>
				)}
			</PageAction>

			<Tabs
				activeKey={section}
				tabBarExtraContent={{
					left: (
						<NavButton
							icon={<AppstoreOutlined />}
							// onClick={toggleDisplay}
							disabled
						/>
					),
				}}
				items={items}
				onChange={handleSectionChange}
				$withLeftExtra
			></Tabs>
		</>
	)
}

export default Scanner
