// Function component (presentational):

import React from 'react'

import { buildWeightItem } from '../measurement/buildWeightItem'
import { buildWaterDrinkingItem } from '../meal/buildMealItems'
import {
	buildNappingItem,
	buildCleaningItem,
	buildWalkingItem,
	buildStairClimbingItem,
	buildStretchingItem,
} from '../activity/buildActivityItems'
import { buildMoodItem } from '../mood/buildMoodItem'
import { buildScanItem } from '../scanner/buildScanItem'

import InlineMenu from '../shared/styledComponents/menu/InlineMenu'

import renderLabel from '../shared/other/renderLabel'

import ItemLabel from '../shared/styledComponents/label/ItemLabel'

import L10nLink from '../shared/navigation/L10nLink'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import {
	CloseOutlined,
	FireOutlined,
	MoreOutlined,
	MessageOutlined,
	ToolOutlined,
	SoundOutlined,
	RedoOutlined,
	AudioOutlined,
	EditOutlined,
} from '@ant-design/icons'
import { Tag, Switch } from 'antd'
import TextCircleButton from '../shared/styledComponents/button/TextCircleButton'

const LibraActions = ({
	renderAssistantAvatar,
	areActionsOpen,
	hideActions,
	isAuthenticated,
	cantShowMoreActions,
	inAssistance,
	isAfterToday,
	showQuickWeight,
	showQuickMeal,
	showQuickMood,
	showQuickActivity,
	handleRepeat,
	handleListening,
	showNewChat,
	isListening,
	t,
}) => {
	const items = []

	const weightItem = buildWeightItem(showQuickWeight, t, isAfterToday)

	const dividerItem = { type: 'divider' }

	const waterDrikingItem = buildWaterDrinkingItem(showQuickMeal, t)

	const moodItem = buildMoodItem(showQuickMood, t, isAfterToday)

	const activityChildren = []

	activityChildren.push(
		buildNappingItem(showQuickActivity, t),
		buildCleaningItem(showQuickActivity, t),
		buildWalkingItem(showQuickActivity, t),
		buildStairClimbingItem(showQuickActivity, t),
		buildStretchingItem(showQuickActivity, t),
	)

	const activityItem = {
		key: 'libra-menu-actions-add-activity',
		icon: <FireOutlined />,
		label: t('event:addActivity.action'),
		children: activityChildren,
	}

	isAuthenticated &&
		!inAssistance &&
		items.push(weightItem, dividerItem, waterDrikingItem, moodItem, activityItem, dividerItem)

	const scanItem = buildScanItem(t)

	!inAssistance && items.push(scanItem, dividerItem)

	const showMoreItem = {
		key: 'libra-menu-actions-show-more',
		icon: <MoreOutlined />,
		label: (
			<ItemLabel onClick={cantShowMoreActions}>{t('nav:libraMenu.actions.showMore')}</ItemLabel>
		),
	}

	!isAuthenticated && !inAssistance && items.push(showMoreItem)

	const assistanceItem = {
		key: 'libra-menu-actions-assistance',
		icon: <MessageOutlined />,
		label: <L10nLink to={'/assistance'}>{t('nav:libraMenu.actions.assistance')}</L10nLink>,
		disabled: inAssistance,
	}

	const optionsChildren = [
		{
			key: 'libra-menu-actions-options-voice',
			icon: <SoundOutlined />,
			label: renderLabel({
				label: t('nav:libraMenu.actions.options.useVoice'),
				extra: <Switch checked disabled />,
			}),
			disabled: true,
		},
		{
			key: 'libra-menu-actions-options-repeat',
			icon: <RedoOutlined />,
			label: (
				<ItemLabel onClick={handleRepeat}>{t('nav:libraMenu.actions.options.repeat')}</ItemLabel>
			),
		},
	]

	const optionsItem = {
		key: 'libra-menu-actions-options',
		icon: <ToolOutlined />,
		label: t('nav:libraMenu.actions.options.title'),
		children: optionsChildren,
	}

	const speakingItem = {
		key: 'libra-menu-actions-speaking',
		icon: <AudioOutlined />,
		label: renderLabel({
			label: (
				<>
					{t('nav:libraMenu.actions.speak')}
					<Tag bordered={false} style={{ marginLeft: 8 }}>
						beta
					</Tag>
				</>
			),
			extra: <Switch checked={isListening} onChange={handleListening} />,
		}),
	}

	const typingItem = {
		key: 'libra-menu-actions-typing',
		icon: <EditOutlined />,
		label: <ItemLabel onClick={showNewChat}>{t('nav:libraMenu.actions.type')}</ItemLabel>,
	}

	isAuthenticated && items.push(assistanceItem, optionsItem, dividerItem, speakingItem, typingItem)

	const renderTitle = () =>
		renderLabel({
			icon: renderAssistantAvatar(),
			label: t('nav:libraMenu.actions.title'),
			extra: <TextCircleButton icon={<CloseOutlined />} onClick={hideActions} />,
		})

	const renderBody = () => <InlineMenu items={items} />

	return (
		<DrawerOrModalContainer
			isOpen={areActionsOpen}
			hide={hideActions}
			renderTitle={renderTitle}
			renderBody={renderBody}
			placement="bottom"
			side="left"
			onlyDrawer
		/>
	)
}

export default LibraActions
